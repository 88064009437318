<template lang="pug">
t-window(v-bind="$attrs", shaped, v-on="$listeners", Zmin-height="200px")

  v-img.white--text(
    Zcontain,
    height="100%",
    Zgradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
    src="/map.png"
  )
    .d-flex.justify-space-between.fill-height
      v-card-title.align-end
        .headline {{ item.name ? item.name : 'Позиция' }}
        //- v-row
          v-col: v-icon mdi-camera
      .ma-3
        v-btn(color="grey darken-3",fab,x-small,dark,@click="show = false")
          v-icon(color="white") mdi-close

        v-menu
          template(v-slot:activator="{ on, attrs }")
            v-btn.ml-1(color="grey darken-3",fab,x-small,dark,@click="show = true")
              v-icon(color="white") mdi-dots-vertical
          CartOptions(v-model="settings")
            t-list-item(title="Enable Picture")
              v-switch(inset v-model="settings.showPic")

  v-bottom-sheet(:value="show" Zpersistent hide-overlay light)
    v-sheet
      v-card-title Cafe Badilico
      v-card-text
        v-row.mx-0(align="center")
          v-rating(:value="4.5" color="amber" dense="" half-increments="" readonly="" size="14")
          .grey--text.ms-4 4.5 (413)
        .my-4.text-subtitle-1 $ • Italian, Cafe
        div Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.
      v-divider.mx-4
      v-card-title Tonight's availability
      v-card-text
        v-chip-group(v-model="selection" active-class="deep-purple accent-4 white--text" column="")
          v-chip 5:30PM
          v-chip 7:30PM
          v-chip 8:00PM
          v-chip 9:00PM
      v-card-actions
        v-btn(color="deep-purple lighten-2" text @click="show = false") Reserve

</template>
<script>
import { mapState, mapActions } from 'vuex'

import CartOptions from 'T@/components/cart/_parts/CartOptions.vue'

export default {
  name: 'Form2',
  components: {
    CartOptions,
  },
  props: {
    dense: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({

    unitTypes: [
      { value: 'PIECE', title: 'шт' },
      { value: 'KILOGRAM', title: 'кг' },
    ],
    show: true,
    panel: null,
    showUnitTypes: true,
    skladAlert: false,
    dialogAlert: false,
    unitTypeMenu: false,
    editMode: false,
    discountMarkupTypes: [{
      title: 'Скидка НГ',
      value: '123',
    },

    ],
    item: {
      barcode: 1000,
      exciseStamp: 'PO6X54654',
      name: 'Терамису',
      price: 1000,
      realQuantity: 1,
      unitType: 'PIECE',
      discountValue: 0,
      markupValue: 0,
      markupType: 'шт',
      markupPercentage: 1,
      total: 1000,
      taxes: [
        { sum: { value: 0 }, percent: 0 },
      ],
    },
    // expand: false,
    // isSklad: false,
    skladAmount: 5,
    tax: 10,
    taxType: 12,
    taxTypes: ['На все', 'На позицию'],
    discountMarkupType: 'DISCOUNT_PERCENTAGE',
    discountMarkupValue: 0,
    suffix: '%',
    configuration: {
      taxMode: false,
    },
    settings: {
      showTotalLine: true,
      showPic: true,
      goToCart: true,
      fullscreen: false,
      ZIStyle: false,
    },
  }),

  computed: {
    ...mapState({
      // cart: (state) => state.tjtheme.cart,
    }),
  },
  methods: {
    ...mapActions({
      addItem: 'tjtheme/addItem',
    }),
  },
}
</script>
<style lang="stylus">
.v-text-field.tright input {
  text-align: end;
}

.v-text-field.tcenter input {
  text-align: center;
}

.v-expansion-panel-content__wrap {
  padding: 0 16px;
}
</style>
